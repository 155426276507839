import React, { useContext, useEffect, useState } from 'react'
import { BlocksContext } from '../context/blocksContext'

export const Advantages = () => {
    const [loaded, setLoaded] = useState(false)
    const blocks = useContext(BlocksContext)
    useEffect(()=>{
        setLoaded(blocks.popup)
    },[blocks.popup])
    return (
        <section className="advantages">
            <div className="cw_main">
                <div className="tm"><span>Преимущества дома</span></div>
                <div className="advant_list">
                    <div className="adv_el">
                       <div className="adv_img_top">
                            <img src={!loaded?"":"/images/advant_img1.jpg"} />
                            <span><l>Многоуровневая <br/> парковка</l></span>
                       </div>
                       <div className="adv_img_bottom">
                           <img src={!loaded?"":"/images/advant_img2.jpg"} />
                           <span><l>Детская и спортивная <br/> площадки</l></span>
                        </div>
                    </div>
                    <div className="adv_el">
                        <img src={!loaded?"":"/images/advant_img3.jpg"} />
                        <span><l>Закрытая территория</l></span>
                    </div>
                    <div className="adv_el">
                       <div className="adv_img_top">
                            <img src={!loaded?"":"/images/advant_img4.jpg"} />
                            <span><l>Видеонаблюдение</l></span>
                       </div>
                       <div className="adv_img_bottom">
                           <img src={!loaded?"":"/images/advant_img5.jpg"} />
                           <span><l>Красочные МОПы <br/> с колясочной</l></span>
                        </div>
                    </div>
                </div>
            </div>
		</section>
    )
}

export default Advantages