import { TextField } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import InputMask from 'react-input-mask/lib/react-input-mask.development'
import { BlocksContext } from "../context/blocksContext"
import { useSendForm } from '../hooks/send-from.hook'

export const Header = () => {
    const blocks = useContext(BlocksContext)
    const sendForm = useSendForm()

    const showPopup = (e)=>{
        e.preventDefault()
        document.querySelector('body').classList.add('overflow')
        document.querySelector('.popup_rgba').style.display="none"
        document.querySelectorAll('.popup_main').forEach(el => {
            el.style.display="none"
        });
        console.log(e.currentTarget.getAttribute('data'))
        document.querySelector('.'+e.currentTarget.getAttribute('data')).style.display="block"
    }

    return (
        <React.Fragment>
            <section className="header">
                <div className="cw_main">
                    <div className="hd_inner">
                        <h1>ЖК “Спутник” <span>в Краснодаре</span></h1>
                        <div className="hd_info">Квартиры класса "Комфорт +"<br/> от 5,46 млн руб.<br/>Сдача дома в IV квартале 2024 года</div>
                    </div>
                </div>
                <img src="images/header_dec_bg.png" className="header_dec_bg dec_bg" />
            </section>
        </React.Fragment>

    )
}
